@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

body {
  background: #fff;
  counter-reset: number 0;
  line-height: 2em;
  font-weight: 400;
  color: #333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  letter-spacing: 0.05em;
  font-size: 16px;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.inner2 {
  margin: 0 auto;
  max-width: 1200px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding: 0 0 100px 0;
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 834px) {
  #contents {
    padding: 0 10px 50px 10px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  background: #1f1a17;
}

@media only screen and (max-width: 834px) {
  header#global_header {
    padding-top: 68px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  background: rgba(36, 36, 36, 0.7);
  z-index: 5;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
}

#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 30px;
}

@media only screen and (max-width: 1200px) {
  #header {
    padding: 15px 10px;
  }
}

@media only screen and (max-width: 834px) {
  #header {
    padding: 0;
  }
}

#header .flxL {
  width: 25%;
}

@media only screen and (max-width: 1000px) {
  #header .flxL {
    width: 30%;
  }
}

@media only screen and (max-width: 834px) {
  #header .flxL #siteID {
    width: 50%;
    margin: 0 auto 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
    right: 0;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#header .flxL #siteID a {
  text-align: left;
  display: block;
}

#header .flxL #siteID img {
  width: 100% !important;
  max-width: 320px;
}

#header .flxR {
  width: 70%;
}

@media only screen and (max-width: 1000px) {
  #header .flxR {
    width: 65%;
  }
}

#header .flxR .wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#header .flxR .wrap #hednav {
  width: 100%;
}

#header .flxR .wrap #hednav ul li a {
  display: block;
}

.navwrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .navwrap {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}

.navwrap #hed_info {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #f1f1f1;
}

.navwrap #hed_info .hoverbtn {
  background: #dcb717;
  transition: 2s;
}

.navwrap #hed_info .hoverbtn .tel2 {
  font-size: 11px;
  display: block;
  line-height: 1;
  letter-spacing: 0;
}

.navwrap #hed_info .hoverbtn .tel2 span {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  letter-spacing: 0.015em;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .navwrap #hed_info .hoverbtn .tel2 span {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .navwrap #hed_info .hoverbtn .tel2 span {
    font-size: 30px;
  }
}

.navwrap #hed_info .hoverbtn .tel2 a {
  opacity: 1;
  display: block;
  color: #f1f1f1;
}

.navwrap #hed_info .store1 p {
  padding: 15px 20px;
}

@media only screen and (max-width: 640px) {
  .navwrap #hed_info .store1 p {
    padding: 0;
  }
  .navwrap #hed_info .store1 p a {
    padding: 10px 20px;
  }
}

.navwrap #hed_info .store1 p i {
  vertical-align: middle;
  margin-right: 7px;
}

.navwrap #hed_info .store2 a {
  padding: 10px 20px;
}

.navwrap #hed_info .store2 a i {
  vertical-align: middle;
}

.navwrap #nav_global ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navwrap #nav_global ul li {
  position: relative;
  flex-grow: 1;
}

.navwrap #nav_global ul li a {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  display: block;
  line-height: 1;
  color: #f1f1f1 !important;
  padding: 20px;
  font-size: 16px;
  font-size: 1vw;
}

@media only screen and (min-width: 1600px) {
  .navwrap #nav_global ul li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .navwrap #nav_global ul li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1000px) {
  .navwrap #nav_global ul li a {
    padding: 10px 10px;
  }
}

.navwrap #nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

.navwrap #nav_global ul li a span {
  margin-top: 10px;
  font-size: 11px;
  display: block;
}

.navwrap #nav_global ul li ul {
  height: auto;
  width: 100%;
  position: absolute;
  display: block;
  background: rgba(27, 73, 150, 0.8);
}

.navwrap #nav_global ul li ul li {
  height: auto;
}

.navwrap #nav_global ul li ul li a {
  height: auto;
  padding: 30px 10px;
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 834px) {
  .global-menu-btn {
    background: #f4f7f6;
    border-right: 1px solid #E6EAEA;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 68px;
    width: 68px;
    z-index: 11;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #000;
    line-height: 1;
    transition: 1s;
  }
  .global-menu-btn:hover {
    color: #fff;
    background: rgba(115, 4, 4, 0.7);
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    display: block;
    width: 68px;
    height: 67px;
    background: #f4f7f6;
    border-left: 1px solid #E6EAEA;
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    color: #000;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  #hednav {
    justify-content: center;
    height: 68px;
    width: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    margin: 0;
    padding: 10px 0;
    background: #dcb717;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #eef2f2;
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    border-bottom: 1px solid #E6EAEA;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5%;
  }
  #hednav .wrap .navwrap #hed_info .store1 {
    margin-bottom: 20px !important;
  }
  #hednav .wrap .navwrap #hed_info .store1 img {
    width: 100% !important;
    max-width: 330px;
  }
  #hednav .wrap .navwrap #hed_info .store1 span {
    display: block;
    width: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn {
    width: 100%;
    margin: 0;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    transition: 1s;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-weight: 700;
    margin-right: 7px;
    font-size: 1.5rem !important;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #E6EAEA;
  }
  #hednav .wrap .navwrap #nav_global ul li ul {
    display: block !important;
    position: static;
    height: auto !important;
  }
  #hednav .wrap .navwrap #nav_global ul li ul li a {
    color: #fff !important;
  }
  #hednav .wrap .navwrap #nav_global ul li ul li a:before {
    display: none;
  }
  #hednav .wrap .navwrap #nav_global ul li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    line-height: 1;
    height: 100%;
    padding: 24px 36px;
    font-size: 16px;
    font-weight: bold;
    color: #202121 !important;
    cursor: pointer;
  }
  #hednav .wrap .navwrap #nav_global ul li a:hover {
    background: #ccc;
  }
  #hednav .wrap .navwrap #nav_global ul li a span {
    display: none;
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

@keyframes mainscl {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes mainscl2 {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}

#mainArea {
  position: relative;
  width: 100%;
}

#mainArea > img {
  width: 100% !important;
  height: auto;
}

#mainArea .mainlogo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  #mainArea .mainlogo {
    max-width: calc(320px / 2);
    margin: auto;
  }
}

#local-keyvisual {
  position: relative;
  background-image: url(../images/common/main_1.jpg);
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

#local-keyvisual #local-keyvisual-title {
  position: relative;
  z-index: 1;
}

#local-keyvisual h1 {
  display: block;
  z-index: 1;
  position: relative;
  color: #fff;
  text-align: center;
  text-shadow: 0px 3px 9.7px rgba(0, 0, 0, 0.35);
  background: rgba(31, 26, 23, 0.35);
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: 400;
  padding: 150px 0;
  line-height: 1.7em;
  font-size: 40px;
  font-size: 2.5vw;
}

@media only screen and (min-width: 1600px) {
  #local-keyvisual h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual h1 {
    font-size: 40px;
    padding: 100px 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 30px;
  }
}

#local-keyvisual h1 span {
  display: block;
  font-size: 0.8em;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.pan {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  margin: 30px 0 100px;
}

@media only screen and (max-width: 834px) {
  .pan {
    display: none;
  }
}

.pan a {
  color: #000;
}

div.translate {
  color: #767676;
  line-height: 1.4;
}

span.translate + span {
  font-size: 16px;
  color: #767676;
  display: block;
  font-weight: normal;
  line-height: 1.4;
}

#builingual {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 2;
  padding: 5px 10px !important;
  background: rgba(255, 255, 255, 0.7) !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between !important;
}

@media only screen and (max-width: 834px) {
  #builingual {
    bottom: 30px;
  }
}

.language {
  color: #333 !important;
  float: none !important;
  padding: 0 !important;
}

.switch2 {
  float: none !important;
  width: 33px;
  height: auto;
  margin-left: 5px !important;
  display: block !important;
}

.switch2 input[type="checkbox"] {
  display: none;
}

.switch2 label {
  display: block;
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(136, 136, 136, 0.4);
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  height: 100%;
  transition: .3s;
}

.switch2 label span {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 5px;
}

div#builingual div.language select {
  margin: 0 !important;
  margin-left: 5px !important;
  padding: 4px 5px !important;
}

/* ON */
.switch2 label span:after {
  content: "OFF";
  font-size: 10px;
  font-weight: 500;
  color: #aaa;
}

.switch2 #cmn-toggle-1:checked + label {
  background-color: #ff7f00;
  box-shadow: none;
}

.switch2 #cmn-toggle-1:checked + label span:after {
  content: "ON";
  color: #fff;
}

.bili_name dl:nth-of-type(2) dd div,
.bili_name dl:last-of-type dd div {
  display: block !important;
}

.bili_name dl:nth-of-type(2) dd .translate,
.bili_name dl:last-of-type dd .translate {
  display: none !important;
}

.translate {
  color: rgba(51, 51, 51, 0.8);
  font-size: 0.8em;
  display: block;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  padding: 50px 0 0;
  background: #1f1a17;
}

@media only screen and (max-width: 834px) {
  footer#global_footer {
    padding: 0;
  }
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

footer#global_footer #nav_footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 960px;
  margin: 0 auto 30px;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer li a {
  color: #fff;
}

#copy {
  padding-top: 50px;
  background: #1f1a17;
  text-align: center;
  font-size: 13px;
  display: block;
  line-height: 40px;
  color: #fff;
}

@media only screen and (max-width: 834px) {
  #copy {
    padding-top: 0px;
    text-align: center;
  }
}

#copy a {
  font-size: 13px;
  color: #fff;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

/* button */
.topBtn a {
  position: relative;
  display: block;
  margin-top: 20px;
  padding: 8px 5px;
  background: #dcb717;
  text-align: center;
  color: #fff;
}

.topBtn a:before, .topBtn a:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  margin: auto;
  content: "";
  vertical-align: middle;
  transition: all .2s;
}

.topBtn a:before {
  width: 23px;
  height: 1px;
  background: #fff;
}

.topBtn a:after {
  width: 12px;
  height: 6px;
  border-top: 1px solid #fff;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.topBtn a:hover {
  opacity: 1;
  background-color: #ffd11d;
  color: #fff;
}

.topBtn a:hover:before, .topBtn a:hover:after {
  right: 25px;
}

.topBtn_insta div {
  text-align: center;
}

.topBtn_insta a {
  max-width: 415px;
  margin: 15px auto 0 auto;
}

.cont_1 {
  padding: 150px 0;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  .cont_1 {
    padding: 50px 10px;
  }
}

.cont_1 article {
  max-width: 1200px;
  margin: 0 auto;
}

.cont_1 h2 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #fff;
  font-weight: 500;
  line-height: 1.4em;
  margin-bottom: 30px;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .cont_1 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_1 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 h2 {
    font-size: 25px;
  }
}

.cont_2 {
  max-width: 1200px;
}

@media only screen and (max-width: 1200px) {
  .cont_2 .box {
    padding: 0 10px;
  }
}

.cont_2 .box:nth-child(odd) article {
  flex-direction: row;
}

.cont_2 .box article {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.cont_2 .box article .text {
  width: 35%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_2 .box article .text {
    width: 40%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_2 .box article .text {
    width: 100%;
    margin-top: 50px;
  }
}

.cont_2 .box article .text h2 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #1f1a17;
  font-weight: 500;
  line-height: 1.4em;
  margin-bottom: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .cont_2 .box article .text h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_2 .box article .text h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2 .box article .text h2 {
    font-size: 25px;
  }
}

.cont_2 .box article img {
  width: 60% !important;
  margin: 0 !important;
}

@media only screen and (max-width: 834px) {
  .cont_2 .box article img {
    width: 55% !important;
  }
}

.cont_3 {
  background: url(../images/common/bg_1.jpg) no-repeat center;
  background-size: cover;
  padding: 80px 0;
}

@media only screen and (max-width: 640px) {
  .cont_3 {
    padding: 50px 0;
  }
}

.cont_3 h3 {
  display: none;
}

.cont_3 .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .inner {
    display: block;
  }
}

.cont_3 .inner .box {
  overflow: hidden;
}

@media only screen and (max-width: 640px) {
  .cont_3 .inner .box:first-child {
    margin-bottom: 50px;
  }
}

.cont_3 .inner .box a:hover img {
  transform: scale(1.2);
}

.cont_3 .inner .box a img {
  transition: 1s;
  transform: scale(1);
}

.cont_3 .inner .box a article {
  position: relative;
}

.cont_3 .inner .box a article > div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
}

.cont_3 .inner .box a article > div .wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  width: 100%;
  height: 100%;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #fff;
  font-weight: 500;
  line-height: 1.4em;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .cont_3 .inner .box a article > div .wrap {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_3 .inner .box a article > div .wrap {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_3 .inner .box a article > div .wrap {
    font-size: 25px;
  }
}

.cont_4 .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .cont_4 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_4 .inner {
    display: block;
  }
}

.cont_4 .inner .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_4 .inner .flxL {
    width: 100%;
    margin-bottom: 50px;
  }
}

.cont_4 .inner .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_4 .inner .flxR {
    width: 100%;
  }
}

.news {
  z-index: 1;
  position: relative;
  height: 469px;
  overflow-y: auto;
  padding-right: 10px;
}

@media only screen and (max-width: 834px) {
  .news {
    height: 450px;
  }
}

.news dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
  display: block !important;
}

.news dl dt, .news dl dd {
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

.fb {
  background: #dcb717;
  padding: 30px;
  text-align: center;
}

.facebook-wrapper {
  max-width: 500px;
  /* Facebook Page Plugin の最大幅 */
  margin: 0 auto;
}

.facebook-wrapper > .fb-page {
  width: 100%;
}

.facebook-wrapper > .fb-page > span,
.facebook-wrapper iframe {
  width: 100% !important;
}

.cont_5 {
  padding: 150px 0;
  color: #fff;
  background: url(../images/common/bg_2.jpg) no-repeat center;
  background-size: cover;
}

@media only screen and (max-width: 640px) {
  .cont_5 {
    padding: 50px 0;
  }
}

.cont_5 h2 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #fff;
  font-weight: 500;
  line-height: 1.4em;
  margin-bottom: 30px;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .cont_5 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_5 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_5 h2 {
    font-size: 25px;
  }
}

.cont_5 .tel2 {
  margin: 40px auto 0;
  max-width: 400px;
  background: #dcb717;
  padding: 15px 30px;
  display: block;
  color: #fff;
  font-weight: 500;
  line-height: 1.4em;
  font-size: 20px;
  font-size: 1.25vw;
}

@media only screen and (min-width: 1600px) {
  .cont_5 .tel2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_5 .tel2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_5 .tel2 {
    font-size: 18px;
  }
}

.cont_5 .tel2 i {
  margin-right: 10px;
}

/* title */
h2.mail.title {
  display: none !important;
}

.midashi h2 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #1f1a17;
  font-weight: 500;
  line-height: 1em;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .midashi h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi h2 {
    font-size: 25px;
  }
}

.midashi2 h2 {
  border-bottom: 1px solid #1f1a17;
  padding-bottom: 15px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #1f1a17;
  font-weight: 400;
  line-height: 1.4em;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .midashi2 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

.midashi3 h2 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  letter-spacing: 0.2em;
  padding: 20px 15px;
  font-weight: 700;
  color: #1f1a17;
  background: #f4f4f4;
  border-left: solid 5px #dcb717;
  border-bottom: solid 3px #d7d7d7;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .midashi3 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi3 h2 {
    font-size: 25px;
  }
}

.midashi4 h2 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #000;
  font-weight: 200;
  line-height: 1.7em;
  font-size: 20px;
  font-size: 1.25vw;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .midashi4 h2 {
    font-size: 20px;
  }
}

.midashi5 {
  display: block;
  width: 100%;
}

.midashi5 h3 {
  border-left: 5px solid #000;
  padding-left: 10px;
  font-family: 'Cormorant Infant', serif;
  color: #111;
  font-weight: 200;
  line-height: 1.4em;
  font-size: 18px;
  font-size: 1.125vw;
}

@media only screen and (min-width: 1600px) {
  .midashi5 h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1200px) {
  .midashi5 h3 {
    font-size: 18px;
  }
}

.concept01 .box article {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.concept01 .box article .text {
  width: 60%;
}

@media only screen and (max-width: 834px) {
  .concept01 .box article .text {
    width: 100%;
    margin-top: 30px;
  }
}

.concept01 .box article .text h3 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #1f1a17;
  font-weight: 500;
  line-height: 1.4em;
  margin-bottom: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  font-size: 20px;
  font-size: 1.25vw;
}

@media only screen and (min-width: 1600px) {
  .concept01 .box article .text h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .concept01 .box article .text h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 834px) {
  .concept01 .box article .text h3 {
    font-size: 18px;
  }
}

.concept01 .box article img {
  width: 35% !important;
  margin: 0 !important;
}

@media only screen and (max-width: 834px) {
  .concept01 .box article img {
    width: 100% !important;
    max-width: 100% !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 834px) and (max-width: 834px) {
  .iapiaperm01 img {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 30px;
    margin-right: 0px !important;
  }
}

.perm {
  padding: 20px;
  border: 3px double #1f1a17;
  color: #dcb717;
}

@media only screen and (max-width: 640px) {
  .comp01 dl {
    display: block !important;
  }
}

.comp01 dl dt {
  background-color: #f7f7f7 !important;
}

@media only screen and (max-width: 1200px) {
  .comp01 dl dt {
    width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .comp01 dl dt {
    width: 100% !important;
    align-items: center !important;
  }
}

.comp01 dl dd {
  background-color: inherit !important;
}

@media only screen and (max-width: 640px) {
  .comp01 dl dd {
    width: 100% !important;
    align-items: center !important;
    text-align: center;
  }
}

.comp01 dl a {
  color: #b2b2b2;
}

@media only screen and (max-width: 640px) {
  .comp02 dl {
    display: block !important;
  }
}

.comp02 dl dt {
  padding: 15px 0 !important;
  border: 0 !important;
  background-color: inherit !important;
}

@media only screen and (max-width: 1200px) {
  .comp02 dl dt {
    width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .comp02 dl dt {
    width: 100% !important;
  }
}

.comp02 dl dd {
  padding: 15px 0 !important;
  border: 0 !important;
  background-color: inherit !important;
}

.comp02 dl a {
  color: #b2b2b2;
}

.comp03 {
  max-width: 1200px;
  margin: 50px auto 0;
  color: #1f1a17;
}

.comp03 td {
  padding: 10px 5px !important;
  border: 0;
  border-bottom: 1px dashed #ccc;
}

.comp03 td:nth-child(1) {
  width: 80% !important;
}

.comp03 td:nth-child(2) {
  text-align: right !important;
}

.waku {
  padding: 40px;
  background: rgba(31, 26, 23, 0.1);
}

@media only screen and (max-width: 640px) {
  .waku {
    padding: 20px;
  }
}

.menubtn {
  margin-left: -2.43902%;
  margin-bottom: 80px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubtn {
    margin-left: -2.43902%;
  }
}

@media only screen and (max-width: 640px) {
  .menubtn {
    justify-content: space-between;
    margin-left: 0;
  }
}

.menubtn .box {
  width: 30.89431%;
  height: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubtn .box {
    width: 30.89431%;
    margin-left: 2.43902%;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .menubtn .box {
    width: 48%;
    margin-left: 0;
  }
}

.menubtn .box a {
  position: relative;
  display: block;
  margin-top: 20px;
  padding: 8px 5px;
  background: #dcb717;
  text-align: center;
  color: #fff;
}

.menubtn .box a:before, .menubtn .box a:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  margin: auto;
  content: "";
  vertical-align: middle;
  transition: all .2s;
}

.menubtn .box a:before {
  width: 23px;
  height: 1px;
  background: #fff;
}

.menubtn .box a:after {
  width: 12px;
  height: 6px;
  border-top: 1px solid #fff;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.menubtn .box a:hover {
  opacity: 1;
  background-color: #ffd11d;
  color: #fff;
}

.menubtn .box a:hover:before, .menubtn .box a:hover:after {
  right: 25px;
}

.menu02 .box article {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.menu02 .box article .text {
  width: 35%;
}

@media only screen and (max-width: 834px) {
  .menu02 .box article .text {
    width: 100%;
    margin-top: 30px;
  }
}

.menu02 .box article .text h2 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #1f1a17;
  font-weight: 500;
  line-height: 1.4em;
  margin-bottom: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .menu02 .box article .text h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .menu02 .box article .text h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .menu02 .box article .text h2 {
    font-size: 25px;
  }
}

.menu02 .box article img {
  width: 60% !important;
  margin: 0 !important;
}

@media only screen and (max-width: 834px) {
  .menu02 .box article img {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 30px;
    margin-right: 0px !important;
  }
}

.items01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .items01 {
    flex-direction: column-reverse;
  }
}

.items01 .flxL {
  width: 45%;
}

@media only screen and (max-width: 834px) {
  .items01 .flxL {
    width: 100%;
    margin-top: 30px;
  }
}

.items01 .flxL h3 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #1f1a17;
  font-weight: 500;
  line-height: 1.4em;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  font-size: 20px;
  font-size: 1.25vw;
}

@media only screen and (min-width: 1600px) {
  .items01 .flxL h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .items01 .flxL h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 834px) {
  .items01 .flxL h3 {
    font-size: 18px;
  }
}

.items01 .flxL .txt {
  font-size: 20px;
  color: red;
}

.items01 .flxR {
  width: 50%;
}

@media only screen and (max-width: 834px) {
  .items01 .flxR {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .continfo img {
    width: 100% !important;
  }
}

.store01 {
  margin-left: -2.43902%;
  margin-bottom: 80px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .store01 {
    margin-left: -2.43902%;
  }
}

@media only screen and (max-width: 640px) {
  .store01 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.store01 .box {
  width: 30.89431%;
  height: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .store01 .box {
    width: 30.89431%;
    margin-left: 2.43902%;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .store01 .box {
    width: 48%;
    margin-left: 0;
  }
}

.kokusai01 {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
  margin-bottom: 50px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .kokusai01 {
    margin-left: -2.43902%;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai01 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.kokusai01 .box {
  position: relative;
  width: 30.89431%;
  height: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .kokusai01 .box {
    width: 30.89431%;
    margin-left: 2.43902%;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai01 .box {
    width: 48%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai01 .box:nth-child(3) {
    width: 100%;
  }
}

.kokusai01 .box img {
  width: 100% !important;
}

.kokusai01 .box > div {
  z-index: 1;
  font-size: 25px;
  font-weight: 600;
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.kokusai02 {
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.kokusai02 .box {
  margin-bottom: 20px;
  width: 31%;
}

.kokusai02 .box article {
  height: 100%;
  display: flex;
  justify-content: flex-start;
}

.kokusai02 .box article h3 {
  min-width: 35px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  vertical-align: middle;
  background-color: #DBDBDB;
  color: #333333;
}

.kokusai03 {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -1.69492%;
  margin-bottom: 50px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .kokusai03 {
    margin-left: -2.1097%;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai03 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.kokusai03 .box {
  position: relative;
  width: 18.30508%;
  height: 18.30508%;
  margin-left: 1.69492%;
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .kokusai03 .box {
    width: 31.2236%;
    margin-left: 2.1097%;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai03 .box {
    width: 48%;
    margin-left: 0;
  }
}

.kokusai03 .box img {
  width: 100% !important;
}

.kokusai03 .box article {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.kokusai03 .box article h3 {
  min-width: 35px;
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  vertical-align: middle;
  background-color: #DBDBDB;
  color: #333333;
}

.contactwrap {
  background: #f7f7f7;
  padding: 50px;
}

.policy a {
  color: #333;
}

.waku2 {
  justify-content: space-between;
}

.waku2 .box {
  width: 48%;
  padding: 40px;
  background: whitesmoke;
  border: double 7px #1b4996;
}

@media only screen and (max-width: 640px) {
  .waku2 .box {
    width: 100%;
    padding: 20px;
  }
}

.waku2 .box img {
  margin-bottom: 20px;
}

.waku2 .box h3 {
  margin-bottom: 15px;
  color: #000;
  font-weight: 700;
  padding-bottom: 15px;
  border-bottom: 2px solid #000;
  font-size: 20px;
  font-size: 1.25vw;
}

@media only screen and (min-width: 1600px) {
  .waku2 .box h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 834px) {
  .waku2 .box h3 {
    font-size: 20px;
  }
}

.member01 {
  padding: 5%;
  background: #f7f7f7;
  border: 1px solid #ccc;
}

.faq_q {
  border: none !important;
}

.faq_q > div {
  font-size: 16px;
  border-bottom: 1px #1b4996 dashed;
  padding-bottom: 20px !important;
  position: relative;
  color: #1b4996;
  font-weight: bold;
  position: relative;
  padding-left: 3em !important;
}

.faq_q > div:before {
  content: "Q";
  display: block;
  background: #1b4996;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -3px;
}

.faq_a {
  border: none !important;
}

.faq_a > div {
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  border-bottom: 1px #376eca solid;
  padding-bottom: 30px !important;
  position: relative;
  padding-left: 3em !important;
}

.faq_a > div:before {
  content: "A";
  display: block;
  background: #376eca;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -6px;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 834px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}

.fadein {
  opacity: 0;
  transition: 2s;
}

@media only screen and (max-width: 834px) {
  .fadein {
    opacity: 1;
    transform: translate(0);
  }
}

.fadein.scrollin {
  opacity: 1;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

@media only screen and (max-width: 834px) {
  .slide-bottom {
    opacity: 1;
    transform: translate(0);
  }
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-bottom2 {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 2000ms;
}

@media only screen and (max-width: 834px) {
  .slide-bottom2 {
    opacity: 1;
    transform: translate(0);
  }
}

.slide-bottom2.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-bottom3 {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 2500ms;
}

@media only screen and (max-width: 834px) {
  .slide-bottom3 {
    opacity: 1;
    transform: translate(0);
  }
}

.slide-bottom3.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

@media only screen and (max-width: 834px) {
  .slide-top {
    opacity: 1;
    transform: translate(0);
  }
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

@media only screen and (max-width: 834px) {
  .slide-right {
    opacity: 1;
    transform: translate(0);
  }
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

@media only screen and (max-width: 834px) {
  .slide-left {
    opacity: 1;
    transform: translate(0);
  }
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}
